const camelRe = /_(\w)/g
const snakeRe = /([A-Z])/g

// 轉成駝峯風格
function toCamelCase(data) {
  if (data instanceof Array) {
    const arr = []
    for (const i in data) {
      arr.push(toCamelCase(data[i]))
    }
    data = arr
  } else if (data !== null && typeof data === 'object') {
    const obj = {}
    for (const key in data) {
      const d = toCamelCase(data[key])
      const newKey = key.replace(camelRe, function(_, letter) {
        return letter.toUpperCase()
      })
      obj[newKey] = d
    }
    data = obj
  }
  return data
}

// 轉成下劃線風格
function toSnakeCase(data) {
  if (data instanceof Array) {
    const arr = []
    for (const i in data) {
      arr.push(toSnakeCase(data[i]))
    }
    data = arr
  } else if (data !== null && typeof data === 'object') {
    const obj = {}
    for (const key in data) {
      const d = toSnakeCase(data[key])
      const newKey = key.replace(snakeRe, function(_, letter) {
        return '_' + letter.toLowerCase()
      })
      obj[newKey] = d
    }
    data = obj
  }
  return data
}

export { toCamelCase, toSnakeCase }
