<template>
  <div class="badge-page">
    <div class="badge-header">
      <div class="banner-container">
        <img src="@/assets/badge/banner.png" alt="" />
      </div>
    </div>
    <!-- 徽章制作页面，录入用户信息 -->

    <div class="badge-container">
      <h3>バッジ作成：</h3>
      <div>
        <h4>資料を記入する：</h4>
        <div class="form-item">
          <div style="width: 180px">氏名：</div>
          <div>
            <!-- <xy-input v-model="name" /> -->
            <el-input req v-model="name" />
          </div>
        </div>

        <div class="form-item">
          <div style="width: 180px">電話番号：</div>
          <div>
            <!-- <xy-input v-model="phone" /> -->
            <el-input v-model="phone" type="number" />
          </div>
        </div>

        <div class="next-btn">
          <button style="text-align: center;color:white;" @click="routeToPreview">
            次へ》
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone: "",
    };
  },
  created() {
    this.$store.dispatch("initial");
  },
  methods: {
    validateForm() {
      if (!this.name || !this.phone) {
        return "名前を入力してください”/“電話番号を入力してください";
      }

      // let re = /^(81)?-?0?[789](?:\d{8}|\d{9})$/;
      // if (!re.test(this.phone)) {
      //   return "名前を入力してください”/“電話番号を入力してください";
      // }

      return true;
    },
    routeToPreview() {
      let res = this.validateForm();
      console.log(res)
      if (res == true) {
        this.$store.dispatch("setName", this.name);
        this.$store.dispatch("setPhone", this.phone);
        this.$router.push({ name: "badge-preview" });
      } else {
        this.$message({
          message: res,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped >
.badge-page {
  margin: 0;
  background-color: rgba(219, 225, 251, 1);
  height: 100vh;
}

.badge-header {
  width: 100%;
  background-color: #6070e1;
  .banner-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
    img {
      width: 100%;
    }
  }
}

.badge-container {
  margin: 20px 20px;
}

.form-item {
  display: flex;
  margin-top: 30px;
  font-size: 20px;
  input {
    font-size: 20px;
  }
}

.next-btn {
  margin-top: 60px;
  text-align: center;
  button {
    border: none;
    font-size: 20px;
    padding: 2px 20px;
    border-radius: 5px;
    margin: 0 auto;
    background-color: rgba(96, 112, 225, 1);
  }
}
</style>