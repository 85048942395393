<template>
  <div class="spars-list">
    <ul>
      <li
        v-for="(item, index) in spars"
        :key="index"
        @click="onSparSelect(item)"
      >
        <img class="spar-icon" :src="item.src" :alt="item.name" />
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  data() {
    return {
      spars: [
        {
          id: 0,
          name: "水晶",
          src: require("@/assets/images/022_16r.png"),
          size: 16,
        },
        {
          id: 2,
          name: "水晶",
          src: require("@/assets/images/101_40r.png"),
          size: 40,
        },
        {
          id: 4,
          name: "水晶",
          src: require("@/assets/images/037_32r.png"),
          size: 32,
        },
      ],
    };
  },
  methods: {
    onSparSelect(item) {
      eventBus.currentSpar = item;
      eventBus.$emit("onSparSelect");
    },
  },
};
</script>

<style lang="scss" scoped>
.spars-list {
  border-color: #efebe3;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.spars-list ul {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
  list-style: none;
}

.spars-list li {
  width: 28px;
  height: 28px;
  justify-self: center;
  padding: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.5s;

  overflow: hidden;
  cursor: pointer;
  background: none;
}

.spar-icon {
  width: 24px;
  height: 24px;
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.25));
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
</style>