<template>
    <div class="make-bracelet">
        <div class="make-bracelet-title">
            <el-row :gutter="20" class="make-bracelet-row">
            <el-col :span="6" class="make-bracelet-row-title">
                <div class="make-bracelet-row-title-text" :class="{'active':active ==0}">
                    <div>Step.1</div>
                    <div>選擇尺寸</div>
                </div> 
                <img v-if="active ==0" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected.png"/>
                <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button.png"/>
            </el-col>
            <el-col :span="6" class="make-bracelet-row-title">
                <div class="make-bracelet-row-title-text" :class="{'active':active ==1}">
                    <div>Step.2</div>
                    <div>選擇主珠</div>
                </div> 
                <img v-if="active ==1" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected.png"/>
                <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button.png"/>
            </el-col>
            <el-col :span="6" class="make-bracelet-row-title">
                <div class="make-bracelet-row-title-text" :class="{'active':active ==2}">
                    <div>Step.3</div>
                    <div>選擇子珠</div>
                </div> 
                <img v-if="active ==2" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected.png"/>
                <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button.png"/>
            </el-col>
            <el-col :span="6" class="make-bracelet-row-title">
                <div class="make-bracelet-row-title-text" :class="{'active':active ==3}">
                    <div>Step.4</div>
                    <div>
                        <div>選擇金屬</div>
                        <div>裝飾配件</div>
                    </div>
                </div> 
                <img v-if="active ==3" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected.png"/>
                <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button.png"/>
            </el-col>
            </el-row>
            <img class="make-bracelet-title-bg" src="@/assets/images/make-bracelet-title-bg.png"/>
        </div>
        <step1 v-if="active ==0"></step1>
        <step2 v-if="active ==1"></step2>
        <step3 v-if="active ==2"></step3>
        <step4 v-if="active ==3"></step4>
    </div>
</template>

<script>
import step1 from './components/step_1.vue'
import step2 from './components/step_2.vue'
import step3 from './components/step_3.vue'
import step4 from './components/step_4.vue'
    export default {
        components: { step1, step2, step3, step4 },
        computed:{
            active(){
               return this.$store.getters.step
            },
            preview(){
               return this.$store.getters.preview
            },
        },
    }
</script>

<style lang="scss" scoped>
.make-bracelet{
    position: relative;
}
.make-bracelet-title{
    position: absolute;
    width: 90%;
    // height: 80px;
    margin: 0 auto;
    left: 50%;
    top: 5.625rem;
    transform: translate(-50%, -50%);
    margin-top: 6.25rem;
}
.make-bracelet-title-bg{
    display: block;
    width: 100%;
}
.make-bracelet-row{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     width: 95%;
}
.make-bracelet-row-title{
    position: relative;
}
.make-bracelet-row-title-text{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
    //  font-size: 0.5625rem;
     width: 100%;
     text-align: center;
     color: #3390AA;
}
.active{
    color: #fff;
}
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
.make-bracelet{
    position: relative;
}
.make-bracelet-title{
    position: absolute;
    width: 90%;
    // height: 80px;
    margin: 0 auto;
    left: 50%;
    top: 5.625rem;
    transform: translate(-50%, -50%);
    // margin-top: 6.25rem;
}
.make-bracelet-title-bg{
    display: block;
    width: 100%;
}
.make-bracelet-row{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     width: 95%;
}
.make-bracelet-row-title{
    position: relative;
}
.make-bracelet-row-title-text{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     font-size: 0.5625rem;
     width: 100%;
     text-align: center;
     color: #3390AA;
}
.active{
    color: #fff;
}
}
</style>