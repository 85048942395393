<template>
  <div>
    <div class="make-bracelet-title">
      <div class="make-bracelet-title-top">{{top}}</div>
      <img class="make-bracelet-img" src="@/assets/images/m99ERR.png" />
      <div class="make-bracelet-title-bottom">{{bottom}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: String,
      default: '選擇主珠',
    },
    bottom: {
      type: String,
      default: '手圍尺寸',
    },
  },
};
</script>

<style lang="scss" scoped>
.make-bracelet-title {
  position: relative;
  width: 50%;
}
.make-bracelet-title-top {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0.375rem;
  left: 50%;
  margin: 0 auto;
  text-align: center;
  transform: translateX(-50%);
  color: #fff;
  font-size: 0.8rem;
}
.make-bracelet-title-bottom {
  position: absolute;
  bottom: 0.375rem;
  left: 50%;
  margin: 0 auto;
  text-align: center;
  transform: translateX(-50%);
  color: #fff;
  font-size: 0.8rem;
  width: 100%;
}
.make-bracelet-img {
  display: block;
  width: 100%;
}
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
  .make-bracelet-title {
  position: relative;
  width: 50%;
}
.make-bracelet-title-top {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 0.375rem;
  left: 50%;
  margin: 0 auto;
  text-align: center;
  transform: translateX(-50%);
  color: #fff;
  font-size: 0.8rem;
}
.make-bracelet-title-bottom {
  position: absolute;
  bottom: 0.375rem;
  left: 50%;
  margin: 0 auto;
  text-align: center;
  transform: translateX(-50%);
  color: #fff;
  font-size: 0.8rem;
  width: 100%;
}
.make-bracelet-img {
  display: block;
  width: 100%;
}
}
</style>