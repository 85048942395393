<template>
  <div class="description-part-step">
    <div>配件說明：</div>
    <div>設計師製作手鏈時，根據你選的配件量對手練進行裝飾</div>
    <div>無：無任何裝飾，只有水晶</div>
    <div>少：4個及以下金屬配件</div>
    <div>多：6個及以上金屬配件</div>
    <div>
      <el-row :gutter="6" type="flex" align="middle">
        <el-col :span="5">備註：</el-col>
        <el-col :span="12"
          ><el-input
            v-model="input"
            placeholder="您想對設計師說............."
          ></el-input
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.description-part-step {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/description-part_bg.png");
  background-size: 100%;
  overflow: hidden;
  border: 0.375rem solid #fdd455;
  padding: 0.625rem;
  color: #af390f;
}
</style>