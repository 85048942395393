<template>
  <div id="spar-info-panel">
    <div id="spar-info-panel-info">
      <div>
        <img :src="currentSpar?.src" alt="" class="spar-icon" />
        <span>水晶・クォーツ</span>
      </div>
      <p class="description">
        すべてのものを浄化し、生命力を活性化させ、潜在能力を引き出し、直感力、創造力をはぐくむなど、石の中でも万能的な存在です。
      </p>
      <p class="stocked">
        <span>8mm玉：</span>
        <span>￥260</span>
        <span>➜ </span>
        <span class="price">￥180</span>
      </p>
      <p class="stocked">
        <span>ボタンカット：</span>
        <span>￥180</span>
        <span>➜ </span>
        <span class="price">￥120</span>
      </p>
    </div>

    <div id="spar-filtter-panel">
      <sparAttrSelect
        v-for="(item, index) in numberDictionaries"
        :key="index"
        v-model="item.current"
        :numberDictionarie="item"
        @change="onFiltterBtnChange"
      />
    </div>

    <sparsList />
  </div>
</template>

<script>
import eventBus from '@/components/eventBus.js'
import sparAttrSelect from "./spar-attr-select.vue";
import sparsList from "./spars-list.vue";
export default {
  components: { sparAttrSelect, sparsList },
  data() {
    return {
      numberDictionaries: [
        {
          name: "生肖石",
          current: "",
          value: ["子鼠", "丑牛", "寅虎", "卯兔", "晨龙", "巳蛇"],
        },
        {
          name: "星座石",
          current: "",
          value: ["狮子座", "水瓶座", "双鱼座", "天秤座", "处女座", "射手座"],
        },
        {
          name: "诞生石",
          current: "",
          value: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        {
          name: "颜色",
          current: "",
          value: ["红", "黄", "蓝", "绿", "青", "橙", "紫", "透明"],
        },
        {
          name: "运势石",
          current: "",
          value: [
            "幸运",
            "恋爱",
            "金运",
            "仕途",
            "愿望成就",
            "健康运",
            "对人关系运",
          ],
        },
      ],
    currentSpar: {}
    };
  },
  created() {
    eventBus.$on('onSparSelect', ()=> {
      this.currentSpar = eventBus.currentSpar
    })
  },
  methods: {
    onFiltterBtnChange() {
      console.log(this.numberDictionaries);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";
#spar-info-panel-info,
#spar-info-panel {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
}

#spar-info-panel-info {
  color: $theme-text-color;
  border-color: $theme-bd-color;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

#spar-info-panel-info p.stocked {
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  padding-left: 0.5rem;
}

#spar-info-panel-info p {
  border-color: $theme-bd-color;
  border-style: dashed;
  border-bottom-width: 1px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
}

.spar-icon {
  width: 24px;
  height: 24px;
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.25));
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

#spar-info-panel-info span {
  margin-left: 0.5rem;
}

#spar-info-panel-info p.description {
  border-width: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: justify;
}

#spar-info-panel-info p {
  border-color: $theme-bd-color;
  border-style: dashed;
  border-width: 0px;
  border-bottom-width: 1px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
}

#spar-info-panel-info p.stocked .price {
  color: #ee777b;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  font-style: italic;
}

#spar-filtter-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  gap: 0.25rem;
}
</style>