<template>
  <div>
    <div class="choose-crystal-title">
      <el-input
        placeholder="请输入内容"
        v-model="input3"
        prefix-icon="el-icon-search"
        class="input-with-select"
      >
        <el-button slot="append">檢索</el-button>
      </el-input>
    </div>
    <div class="choose-crystal-content">
      <template v-for="(item, index) in list">
        <el-row
          :gutter="8"
          :key="index + 'a'"
          v-if="index % 3 == 0"
          type="flex"
        >
          <el-col
            :span="8"
            class="col-list"
            v-for="(v, j) in list.slice(index, index + 3)"
            :key="`${index}y` + j"
          >
            <chooseCrystalItem :value="v" @itemLabel="itemLabel"></chooseCrystalItem>
          </el-col>
        </el-row>
      </template>
      <div class="choose-crystal-label">
           <div class="choose-crystal-label-title">
              您所選擇的是>
           </div>
           <div class="choose-crystal-label-content">
                <el-tag size="mini" @close="close(index)" v-for="(item,index) in step2labels " :key="index" closable type="success">{{item.top}}:{{item.label}} </el-tag>
           </div>
      </div>
      <template v-for="(item, index) in is" >
        <el-row :gutter="10" type="flex" :key="index + 'p'" v-if="index % 12 == 0">
          <el-col :span="2"  v-for="(v, j) in is.slice(index, index + 12)"   :key="`${index}y` + j" class="choose-crystal-el-col" >
            <img class="choose-img-bg" src="@/assets/images/037_32r.png" />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import chooseCrystalItem from "../components/chooseCrystalItem.vue";
export default {
  components: { chooseCrystalItem },
  data() {
    return {
      newItem:[],
      input3: "",
      is:[
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
        'dsada',
      ],
      list: [
        {
          top: "全部",
          children: [],
        },
        {
          top: "七脈輪",
          children: [
            {
              label: "頂輪",
            },
            {
              label: "眉心輪",
            },
            {
              label: "喉輪",
            },
            {
              label: "心輪",
            },
            {
              label: "太陽輪",
            },
            {
              label: "臍輪",
            },
            {
              label: "海底輪",
            },
          ],
        },
        {
          top: "店長推薦",
          children: [
            {
              label: "月亮石",
            },
            {
              label: "海藍寶",
            },
            {
              label: "青金石",
            },
            {
              label: "黑曜石",
            },
            {
              label: "螢石",
            },
            {
              label: "黑髮晶",
            },
          ],
        },
        {
          top: "運程",
          children: [
            {
              label: "金運&商壳",
            },
            {
              label: "仕業運&人際關係",
            },
            {
              label: "戀愛運&良緣",
            },
            {
              label: "健康運&療し",
            },
            {
              label: "親人&家庭圓滿",
            },
            {
              label: "守護&厄払い",
            },
          ],
        },
        {
          top: "十二誕生石",
          children: [
            {
              label: "石榴石",
            },
            {
              label: "紫水晶",
            },
            {
              label: "舒俱萊",
            },
            {
              label: "海藍寶",
            },
            {
              label: "雞血石",
            },
            {
              label: "閃靈鑽",
            },
            {
              label: "白水晶",
            },
            {
              label: "砂金石",
            },
            {
              label: "祖母綠",
            },
            {
              label: "月光石",
            },
            {
              label: "天河石",
            },
            {
              label: "紅紋石",
            },
            {
              label: "紅寶石",
            },
            {
              label: "橄欖石",
            },
            {
              label: "纏絲瑪瑙",
            },
            {
              label: "青金石",
            },
            {
              label: "藍寶石",
            },
            {
              label: "黃水晶",
            },
            {
              label: "托帕石",
            },
            {
              label: "藍色托帕石",
            },
            {
              label: "綠松石",
            },
            {
              label: "鋯石",
            },
          ],
        },
        {
          top: "十二生肖",
          children: [
            {
              label: "鼠",
            },
            {
              label: "虎",
            },
            {
              label: "兔",
            },
            {
              label: "龍",
            },
            {
              label: "蛇",
            },
            {
              label: "馬",
            },
            {
              label: "羊",
            },
            {
              label: "猴",
            },
            {
              label: "雞",
            },
            {
              label: "狗",
            },
            {
              label: "猪",
            },
          ],
        },
        {
          top: "十二星座",
          children: [
            {
              label: "摩羯座",
            },
            {
              label: "水瓶座",
            },
            {
              label: "雙魚座",
            },
            {
              label: "白羊座",
            },
            {
              label: "金牛座",
            },
            {
              label: "雙子座",
            },
            {
              label: "巨蟹座",
            },
            {
              label: "獅子座",
            },
            {
              label: "處女座",
            },
            {
              label: "天秤座",
            },
            {
              label: "天蝎座",
            },
            {
              label: "射手座",
            },
          ],
        },
        {
          top: "超強能量",
          children: [
            {
              label: "超級七",
            },
            {
              label: "閃靈鑽",
            },
            {
              label: "黑金超",
            },
            {
              label: "銅髮晶",
            },
            {
              label: "紅紋石",
            },
            {
              label: "碧璽",
            },
            {
              label: "幽靈",
            },
            {
              label: "太赫茲",
            },
            {
              label: "拉長石",
            },
          ],
        },
      ],
    };
  },
  computed: {
    step2labels() {
      return this.unique(this.$store.getters.step2label);
    },
  },
  methods:{
     close(index){
       this.newItem.splice(index, 1); 
       this.$store.dispatch("setStep2label", this.newItem);
     },
     itemLabel(item){
       this.newItem.push(item)
       this.$store.dispatch("setStep2label", this.unique(this.newItem));
     },
     unique (arr) {
        const obj = {}
        arr = arr.reduce((cur, next) => {
          obj[next.label] ? '' : obj[next.label] = true && cur.push(next)
          return cur
        }, [])
        return arr
     }
  }
}
</script>

<style lang="scss" scoped>
.choose-crystal-title {
  margin: 0.9375rem 0;
}
.choose-crystal-content {
  min-height: 400px;
  border-radius: 10px;
  border: 3px dashed #6ad0a5;
  background-color: rgba(255, 255, 255, 0.84);
  padding: 8px;
}
.col-list {
  margin-bottom: 0.625rem;
}
.choose-crystal-content-item {
  position: relative;
  padding: 0.5rem;
}
.choose-bg-top-text {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.625rem;
}
.choose-bg-top-img {
  display: block;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.choose-img-bg {
  display: block;
  width: 100%;
}
.choose-crystal-label{
  margin-bottom: 0.625rem;
}
.choose-crystal-label-title{
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 1.25rem;
  color: #fff;
  margin-bottom: 0.5rem;
  background-color: #388c6c;
  border: 1px solid #6ad0a5;
  color: #c1f4dc;
}
.choose-crystal-el-col{
  margin-bottom: 0.625rem;
}
::v-deep{
  .el-tag{
    margin: 0.375rem;
  }
}
</style>