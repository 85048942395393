<template>
  <div
    class="badge-page"
    @click="routerToCurrentPage"
    :style="preview == null ? 'height:100vh;' : ''"
  >
    <div class="upload-avatar-container">
      <div class="badge-header">
        <div class="banner-container">
          <img src="@/assets/badge/banner.png" alt="" />
        </div>
      </div>
      <!-- 徽章制作页面，录入用户信息 -->
      <div class="badge-container">
        <div
          v-if="preview == null"
          style="text-align: center; font-size: 20px; margin-top: 50px"
        >
          画像をアップロード
        </div>

        <div
          class="badge-image-container"
          :style="preview != null ? ' pointer-events: none;' : ''"
        >
          <img src="@/assets/badge/badge.png" alt="" />
          <img
            class="dotted-line"
            src="@/assets/badge/dotted_line.png"
            alt=""
          />
          <vueCropper
            :info="false"
            :canMove="preview != null ? false : true"
            ref="cropper"
            class="avatar-container"
            :img="option.img"
            :outputType="option.outputType"
            :autoCrop="true"
            :canMoveBox="false"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="true"
          >
          </vueCropper>
        </div>
      </div>

      <div v-if="preview == null" class="badge-buttons">
        <button @click="selectFile">画像を選択</button>
        <button @click="clip">次のステップ</button>
      </div>

      <div class="badge-bottom-container">
        <img src="@/assets/badge/contactUs.png" />
      </div>
    </div>

    <input
      id="fileinp"
      type="file"
      style="display: none"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      @change="uploadImg($event)"
      ref="uploadImg"
    />
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { file } from "@/api/files.js";
export default {
  props: ["preview"],
  components: {
    VueCropper,
  },
  data() {
    return {
      name: "",
      phone: "",
      avatar: null,
      avatarImg: null,
      seed: null,
      option: {
        size: 0.5,
        img: "",
        outputType: "png",
        autoCropWidth: 0,
        autoCropHeight: 0,
      },
    };
  },
  created() {
    if (this.preview == null) {
      this.seed = this.$route.params.seed;
    } else {
      this.seed = this.preview;
    }
    this.option.img = this.$store.getters[`avatar${this.seed}Base64`];
  },
  mounted() {
    let interval = setInterval(() => {
      this.option.autoCropWidth =
        document.getElementsByClassName("avatar-container")[0].clientWidth;
      this.option.autoCropHeight =
        document.getElementsByClassName("avatar-container")[0].clientHeight;
      if (this.option.autoCropHeight > 100) {
        clearInterval(interval);
      }
    }, 100);
  },
  methods: {
    routerToCurrentPage() {
      if (this.preview != null) {
        this.$router.push({
          name: "badge-upload",
          params: { seed: this.preview },
        });
      }
    },
    base64toFile(dataurl, filename = "file") {
      if (dataurl == null) {
        return null;
      }
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    selectFile() {
      // this.avatar.resetImage();
      this.avatarImg = null;
      document.getElementById("fileinp").click();
    },
    async clip() {
      let self = this;
      if (this.option.img) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let data = await new Promise((resolve) => {
          this.$refs.cropper.getCropData((data) => {
            resolve(data);
          });
        });

        const formData = new FormData();
        formData.append("file", this.base64toFile(data));
        await file(formData).then((result) => {
          this.$store.dispatch(`setAvatar${self.seed}Base64`, result.data);
          self.option.img = result.data;
        });
        loading.close();
        // this.$store.dispatch(`setAvatar${self.seed}Base64`, data);
        // self.option.img = data;
        self.$router.replace({ name: "badge-preview" });
      } else {
        self.$router.replace({ name: "badge-preview" });
      }
    },

    uploadImg(e) {
      //上传图片
      // this.option.img
      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
        this.$refs.uploadImg.value = "";
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>
<style>
.cropper-modal {
  background: none !important;
}
</style>

<style lang="scss" scoped>
.upload-avatar-container {
  background-color: rgba(219, 225, 251, 1);
  user-select: none;
  // z-index: 999;
}

.badge-page {
  background-color: rgba(219, 225, 251, 1);
  // height: 100%;
}

.badge-header {
  width: 100%;
  background-color: #6070e1;
  .banner-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
    img {
      width: 100%;
    }
  }
}

.badge-container {
  margin: 20px 20px;
}

.badge-image-container {
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
  .avatar-container {
    position: absolute;
    top: 7.2%;
    left: 11%;
    z-index: 2;
    border-radius: 50%;
    width: 77.5%;
    height: 83%;
    overflow: hidden;
    // background-color: rgba(0, 0, 0, 0.1);
  }

  img {
    width: 90%;
    height: 100%;
  }

  .dotted-line {
    left: 18%;
    top: 14%;
    width: 64%;
    height: 70%;
    position: absolute;
    z-index: 3;
    pointer-events: none;
  }
}

#Mavatar-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

.badge-bottom-container {
  width: 90%;
  margin: 40px auto;
  img {
    width: 100%;
  }
}
.vue-cropper {
  background-image: none !important;
}

.badge-buttons {
  text-align: center;
  margin: 40px auto;
  button {
    color: white;
    background-color: #6070e1;
    border: none;
    font-size: 18px;
    padding: 10px 20px;
    margin: 0px 10px;
  }
}
</style>