import axios from 'axios'
import { toSnakeCase, toCamelCase } from './data-format'
import { Message } from 'element-ui';
// import { refreshAccessToken,  } from '@/api/security'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || '/api',
  timeout: 30000
})

http.interceptors.request.use(
  config => {
    // config.headers.common['accept-language'] = `zh_tc`
    if (!config.unformat) {
      config.params = toSnakeCase(config.params)
      config.data = toSnakeCase(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    if (response.headers['content-type'] === 'application/json') {
      response.data = toCamelCase(response.data)
    }
    return response
  },
  error => {
    console.log(error.response)
    // if (error.response.status === 500) {
    // Message.error(error.response.data.message);
    // } else {
    Message.error(error.response.data.error_message);
    // }
    return Promise.reject(error)
  }
)

export default http
