<template>
    <div class="make-bracelet-step">
        <div class="title-box">
            <titleImg top="選擇主珠" :bottom="`直徑尺寸`"></titleImg>
        </div>
         <el-row :gutter="20" class="button-group step-button-group">
                <el-col :span="8" class="step-list" @click.native="onSetDiameter(8)"><span class="step-text">·8mm·</span>
                <img  v-if="diameter==8" src="@/assets/images/step_2-button-selected.png"/>
                <img  v-else src="@/assets/images/step_2-button.png"/>
                </el-col>
                <el-col :span="8" class="step-list" @click.native="onSetDiameter(10)"><span class="step-text">·10mm·</span><img  v-if="diameter==10" src="@/assets/images/step_2-button-selected.png"/>
                <img  v-else src="@/assets/images/step_2-button.png"/></el-col>
                <el-col :span="8" class="step-list" @click.native="onSetDiameter(12)"><span class="step-text">·12mm·</span><img  v-if="diameter==12" src="@/assets/images/step_2-button-selected.png"/>
                <img  v-else src="@/assets/images/step_2-button.png"/></el-col>
        </el-row>
         <div class="title-preview">
            <titleImg top="手链預覽" :bottom="`三芒星（手圍${$store.getters.preview}cm）`"></titleImg>
        </div>
        <div class="make-bracelet-content">

        </div>
         <div class="title-preview">
            <titleImg top="水晶能量" bottom="選擇"></titleImg>
        </div>
        <div class="make-bracelet-hand-around">
          <chooseCrystal></chooseCrystal>
        </div>
        <div class="make-bracelet-hand-around">
          <stepIntroduce></stepIntroduce>
        </div>
        <div class="button-group-box">
            <el-row :gutter="50" class="button-group">
                <el-col :span="12"><img @click="onPreviousStep" src="@/assets/images/previous-step.png"/></el-col>
                <el-col :span="12"><img @click="onNextStep" src="@/assets/images/next-step.png"/></el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import titleImg from '../components/titleImg.vue'
import stepIntroduce from '../components/stepIntroduce.vue'
import chooseCrystal from '../components/chooseCrystal.vue'
    export default {
        components: { titleImg, stepIntroduce,chooseCrystal },
        computed:{
            diameter(){
               return this.$store.getters.diameter
            },
        },
        methods:{
            onSetDiameter(v){
                this.$store.dispatch("setDiameter", v);
                this.$forceUpdate()
            },
            onPreviousStep(){
                this.$store.dispatch("setStep", 0);
            },
            onNextStep(){
                this.$store.dispatch("setStep", 2);
            },
        }
    }
</script>

<style lang="scss" scoped>
.make-bracelet-step{
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/bg_3.png');
    background-size: 100%;
    overflow: hidden;
}
.title-box{
  margin-top: 9.375rem;   
}
.title-preview{
  margin: 1.25rem 0;
}
.make-bracelet-img{
    width: 12.5rem;
}
.button-group-box{
    overflow: hidden;
    padding: 1.25rem;
}
.step-button-group{
    padding: 0 1.25rem;
    margin-top: 1.25rem;
}
.step-list{
    position: relative;
}
.step-text{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
}
.make-bracelet-hand-around{
    margin: 0 1.25rem;
    margin-bottom: 1.25rem;
}


.make-bracelet-content{
    background: #fff;
    height: 400px;
}
.button-group img{
    display: block;
    width: 100%;
}

</style>