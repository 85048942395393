<template>
  <div class="spar-attr-panel">
    <div class="spar-attr-curr">
      {{ numberDictionarie.name }}{{ current ? ": " + current : "" }}
    </div>

    <div class="spar-attr-list">
      <div
        v-for="(item, index) in numberDictionarie.value"
        :key="index"
        class="spar-attr-list-li"
        @click="current = item"
      >
        {{ item }}
      </div>
      <div class="spar-attr-list-li" @click="current = ''">全部</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberDictionarie: Object,
    value: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    current: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.spar-attr-curr {
  padding: 5px;
  border-width: 1px;
  border-color: #efebe3;
  color: #876e5e;
  border-style: dashed;
  cursor: pointer;
}

.spar-attr-panel {
  position: relative;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(55, 65, 81);
}

.spar-attr-list {
  display: none;
}

.spar-attr-panel:hover .spar-attr-list {
  width: 100%;
  display: block;
  z-index: 999;
  position: absolute;
  text-align: center;
  background: white;
  cursor: pointer;
}

.spar-attr-list-li {
  border-width: 1px;
  border-style: solid;
  color: #876e5e;
  border-color: #efebe3;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.spar-attr-list:active::after{
  display: none;
}
</style>