<template>
    <div class="step-introduce-box">
        <img class="step-introduce-top" src="@/assets/images/step-introduce-top.png" />
        <img class="step-introduce-in" src="@/assets/images/step-introduce-in.png" />
       <div class="step-introduce-title">
        dsadadsadasd
       </div>
       <div class="step-introduce-content">
            <el-checkbox-group v-model="checkList" text-color="#606266" fill="#E6B0AA">
                <el-checkbox label="复选框 A" >
                        <el-row type="flex">
                        <el-col :span="5">
                            <div>紫晶石:</div>
                            <div>紫晶石:</div>
                        </el-col>
                        <el-col  class="step-introduce-describe">cxzccxzcz大师傅犯得上发射点法大师傅士大夫士大夫犯得上发生范德萨反对</el-col>
                        <el-col :span="2">--</el-col>
                        <el-col :span="2"><div class="grid-content">1</div></el-col>
                        </el-row>
                </el-checkbox>
                <el-checkbox label="复选框 B"></el-checkbox>
                <el-checkbox label="复选框 C"></el-checkbox>
            </el-checkbox-group>
       </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                checkList: ['选中且禁用','复选框 A']
            };
        }
    }
</script>

<style lang="scss" scoped>
.step-introduce-box{
   background-color: #FBF6EC;
   border: 10px solid #E6B0AA;
   border-radius: 20px;
}
.step-introduce-title{
  margin:0 1.25rem;
  margin-top: 1.875rem;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}
.step-introduce-top{
    display: block;
    width: 6.25rem;
    margin: 0 auto;
}
.step-introduce-in{
    display: block;
    width: 9.375rem;
    margin: 0 auto;
    margin-top: 1.25rem;
}

.step-introduce-content{
    margin:0 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
    padding:  1.25rem 0.3125rem;
    border: 2px solid #E6B0AA;
    
}
.step-introduce-describe{
    word-break:break-all;
     overflow-wrap: break-word;
     white-space: normal;
    font-size: 0.625rem;
}
.grid-content{
    width: 19px;
    height: 19px;
    background: #E6B0AA;
    border-radius: 50%;
    text-align: center;
    font-size: 0.625rem;
    flex: 1;
    color: #fff;
}
::v-deep{
    .el-checkbox-group{
        display: flex;
        flex-direction: column;
    }
    .el-checkbox{
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        margin-right: 0;
    }
    .el-checkbox__label{
        flex: 1;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #E6B0AA;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner{
            background-color: #E6B0AA;
            border-color: #E6B0AA;
    }
}
</style>