const badge = {
    state: {
      name: "",
      phone: "",
      avatar0Base64: "",
      avatar1Base64: "",
      avatar2Base64: "",
      avatar3Base64: "",
    },
    mutations: {
      initial:(state) =>{
        state.name = ''
        state.phone = ''
        state.avatar0Base64 = ''
        state.avatar1Base64 = ''
        state.avatar2Base64 = ''
        state.avatar3Base64 = ''
      },
      setName: (state, name) => {
          state.name = name
      },
      setPhone: (state, phone) => {
          state.phone = phone
      },
      setAvatar0Base64: (state, avatar0Base64) => {
          state.avatar0Base64 = avatar0Base64
      },
      setAvatar1Base64: (state, avatar1Base64) => {
          state.avatar1Base64 = avatar1Base64
      },
      setAvatar2Base64: (state, avatar2Base64) => {
          state.avatar2Base64 = avatar2Base64
      },
      setAvatar3Base64: (state, avatar3Base64) => {
          state.avatar3Base64 = avatar3Base64
      },
    },
    actions: {
      initial(context) {
        context.commit('initial')
      },
      setName(context,name) {
        context.commit('setName', name)
      },
      setPhone(context,phone) {
        context.commit('setPhone', phone)
      },
      setAvatar0Base64(context,avatar0Base64) {
        context.commit('setAvatar0Base64', avatar0Base64)
      },
      setAvatar1Base64(context,avatar1Base64) {
        context.commit('setAvatar1Base64', avatar1Base64)
      },
      setAvatar2Base64(context,avatar2Base64) {
        context.commit('setAvatar2Base64', avatar2Base64)
      },
      setAvatar3Base64(context,avatar3Base64) {
        context.commit('setAvatar3Base64', avatar3Base64)
      },
    }
}

export default badge