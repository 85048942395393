const step = {
    state: {
      ///记录步骤
      step: 0,
      ///手围尺寸
      preview: 15,
      ///主珠直径
      diameter: 8,
      step2label: []
    },
    mutations: {
      initial:(state) =>{
        state.step = 0
        state.preview = 15
        state.diameter = 8
      },
      setStep: (state, step) => {
          state.step = step
        },
      setPreview: (state, preview) => {
          state.preview = preview
        },
      setDiameter: (state, diameter) => {
          state.diameter = diameter
        },
      setStep2label: (state, step2label) => {
          state.step2label= step2label
      },
    },
    actions: {
      initial(context) {
        context.commit('initial')
      },
      setStep(context,step) {
        context.commit('setStep', step)
      },
      setPreview(context,preview) {
        context.commit('setPreview', preview)
      },
      setDiameter(context,diameter) {
        context.commit('setDiameter', diameter)
      },
      setStep2label(context,step2label) {
        context.commit('setStep2label', step2label)
      },
    }
}

export default step