<template>
    <div class="make-bracelet-step">
         <div class="title-box title-preview">
            <titleImg top="手链預覽" :bottom="`三芒星（手圍${diameter}cm）`"></titleImg>
        </div>
        <div class="make-bracelet-content">

        </div>
         <div class="title-preview">
            <titleImg top="水晶能量" bottom="選擇"></titleImg>
        </div>
        <div class="make-bracelet-hand-around">
            <el-row :gutter="20" class="button-group step-button-group">
               <el-col :span="8" class="step-list" @click.native="onSetAccessories(8)"><span class="step-text">·無·</span>
                <img  src="@/assets/images/step_4-button.png"/>
                </el-col>
                <el-col :span="8" class="step-list" @click.native="onSetAccessories(8)"><span class="step-text">·少·</span>
                <img  src="@/assets/images/step_4-button.png"/>
                </el-col>
                <el-col :span="8" class="step-list" @click.native="onSetAccessories(8)"><span class="step-text">·多·</span>
                <img  src="@/assets/images/step_4-button.png"/>
                </el-col>
            </el-row>
          <descriptionPart></descriptionPart>
        </div>
        <div class="make-bracelet-hand-around">
          <stepIntroduce></stepIntroduce>
        </div>
        <div class="make-bracelet-hand-around bracelets-number">
            <el-row class="bracelets-number-content" type="flex" align="middle">
                <el-col :span="12"><div>請選擇手鏈數量</div></el-col>
                <el-col :span="12"><el-input-number v-model="num"  :min="1" :max="10" label="描述文字"></el-input-number></el-col>
            </el-row>
           <img class="bracelets-number-img" src="@/assets/images/bracelets-number.png" />
        </div>
        <div class="make-bracelet-hand-around">
             <div class="number-combined">
                <img class="bracelets-number-img" src="@/assets/images/combined-top.png"/>
                <div class="number-combined-content">
                   <div class="number-combined-list">
                      <el-row type="flex" justify="space-between">
                          <el-col :span="8">dasda</el-col>
                          <el-col :span="16" style="text-align:right;">dasdadd</el-col>
                      </el-row>
                   </div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                   <div class="number-combined-list">sdfsfsdfdsfdsf</div>
                </div>
                <img class="bracelets-number-img" src="@/assets/images/combined-bottom.png"/>
             </div>
        </div>
        <div class="button-group-box">
            <el-row :gutter="50" class="button-group">
                <el-col :span="12"><img @click="onPreviousStep" src="@/assets/images/previous-step.png"/></el-col>
                <el-col :span="12"><img @click="onNextStep" src="@/assets/images/next-step.png"/></el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import titleImg from './titleImg.vue'
import stepIntroduce from './stepIntroduce.vue'
import descriptionPart from './descriptionPart.vue'
    export default {
        components: { titleImg, stepIntroduce,descriptionPart },
        data() {
            return {
                num: 1
            }
        },
        computed:{
            diameter(){
               return this.$store.getters.diameter
            },
        },
        methods:{
            onSetAccessories(v){
                this.$store.dispatch("setDiameter", v);
                this.$forceUpdate()
            },
            onPreviousStep(){
                this.$store.dispatch("setStep", 0);
            },
            onNextStep(){
                this.$store.dispatch("setStep", 1);
            },
        }
    }
</script>

<style lang="scss" scoped>
.make-bracelet-step{
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/step_4_bg.png');
    background-size: 100%;
    overflow: hidden;
}

.title-preview{
  margin: 1.25rem 0;
}
.title-box{
  margin-top: 9.375rem;   
}
.make-bracelet-img{
    width: 12.5rem;
}
.button-group-box{
    overflow: hidden;
    padding: 1.25rem;
}
.step-button-group{
    padding: 0 1.25rem;
    margin-top: 1.25rem;
    color: #fff;
    margin-bottom: 1.25rem;
}
.step-list{
    position: relative;
}
.step-text{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
}
.make-bracelet-hand-around{
    margin: 0 1.25rem;
    margin-bottom: 1.25rem;
}
.make-bracelet-content{
    background: #fff;
    height: 400px;
}
.button-group img{
    display: block;
    width: 100%;
}
.bracelets-number-img{
    display: block;
    width: 100%;
}
.bracelets-number{
    position: relative;
}
.bracelets-number-content{
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.number-combined{
    border-radius: 0.625rem;
    padding: 1rem;
    background-color: #fff8f1;
}
.number-combined-content{
    border-left: 2px solid #b7964b;
}
.number-combined-content{
    padding-left: 1.25rem;
}
.number-combined-list{
    padding: 8px 0;
    border-bottom: 2px solid #b7964b;
    border-image: url('~@/assets/images/number-combined-list-bg.png') 27 ;
}
::v-deep{
    .el-input-number{
        width: 100%;
        background-image: url('~@/assets/images/number_bg.png');
        background-size: 100%;
    }
    .el-input-number__decrease, .el-input-number__increase{
       background-color: rgb(0 0 0 / 0%);
       border: 0;
       color: #000;
       font-size: 1.25rem;
    }
    .el-input-number__decrease{
       border-right: 2px solid #fbd255;
    }
    .el-input-number__increase{
        border-left: 2px solid #fbd255;
    }
    .el-input-number .el-input__inner{
       background-color: rgb(0 0 0 / 0%);
       border: 0;
    }
}
</style>