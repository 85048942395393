<template>
  <div>
    手围大小 (15,17,19,21)
    <el-input v-model="handSize" />
    几芒星 (1,2,3,4,5,6,8)
    <el-input v-model="mainJewelryNum" />
    主珠大小大小 (8,10,12)
    <el-input v-model="mainJewelrySize" />
    子珠大小 (6，8，10，12)
    <el-input v-model="jewelrySize" />
    <div
      id="test"
      :style="`width:${400 * zoom}px;height:${
        400 * zoom
      }px;position: relative;margin:100px auto;`"
    >
      <canvas width="400" height="400" id="canvas_1" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zoom: 1,
      mainJewelrySize: 12,
      mainJewelryNum: 1,
      jewelrySize: 6,
      handSize: 15,
    };
  },
  mounted() {
    this.paint();
  },
  watch: {
    handSize() {
      this.paint();
    },
    mainJewelrySize() {
      this.paint();
    },
    mainJewelryNum() {
      this.paint();
    },
    jewelrySize() {
      this.paint();
    },
  },
  methods: {
    //生成360个点，每个点画个圆，例碰到上一个圆 则跳过该点，最后得出 真正绘画出的点数。 等除
    paint() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.clearRect(0, 0,400,400);

      //绘画圆形
      this.drawCirc();
      //绘制主晶石
      let data = this.calcCircularLayout(
        this.mainJewelryNum,
        { x: 200, y: 200 },
        this.handSize
      );
      this.drawMainJewelry(data);
      // //绘制子珠
      let pointsData = this.calcCircularLayout(
        (this.handSize * 100 * 3.14).toFixed(0),
        { x: 200, y: 200 },
        this.handSize
      );
      this.drawTestCircular(pointsData, data);
    },
    group(array, subNum) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subNum)));
      }
      return newArray;
    },
    /**
     * 计算手串子珠数量 用 手围 - 主珠直径 * 主珠数量 / 主珠数量 / 子珠直径 = 子珠数量 / 主珠数量 =  子珠在每一间隙中的数量
     */
    drawTestCircular(data, mainJewelryData) {
      // console.log(data, mainJewelryData);
      // let temp = [];
      // let i = 0;

      // var ctx = document.getElementById("canvas_1").getContext("2d");
      // ctx.beginPath();
      // // ctx.arc(200, 200+this.handSize*10- this.mainJewelrySize*10-10, 1, 0, 2 * Math.PI);
      // ctx.stroke();
      // let flag = true;
      // data.forEach((item) => {
      //   if (!mainJewelryData[i]) {
      //     mainJewelryData.push({
      //       x: mainJewelryData[0].x,
      //       y: mainJewelryData[0].y,
      //     });
      //   }
      //   //判断该点是否在主晶石范围里是的话 进入碰撞状态 直到跳出后 线段加1

      //   // if (item.x == mainJewelryData[i].x && item.y == mainJewelryData[i].y) {
      //   //   i++;
      //   // }

      //   let circle = {
      //     x: item.x,
      //     y: item.y,
      //     radius: 1,
      //   };

      //   console.log(i);
      //   let mainJewelry = {
      //     x: mainJewelryData[i].x,
      //     y: mainJewelryData[i].y,
      //     radius: this.mainJewelrySize,
      //   };

      //   let secondMainJewelry = {
      //     x: mainJewelryData[i + 1 >= mainJewelryData.length ? 0 : i + 1].x,
      //     y: mainJewelryData[i + 1 >= mainJewelryData.length ? 0 : i + 1].y,
      //     radius: this.mainJewelrySize,
      //   };

      //   if (
      //     this.checkCircle(circle, mainJewelry) ||
      //     this.checkCircle(circle, secondMainJewelry)
      //   ) {
      //     //碰前珠 后珠都没碰到。 认为进入新的线段
      //     flag = false;
      //   } else if (
      //     !this.checkCircle(circle, mainJewelry) &&
      //     !this.checkCircle(circle, secondMainJewelry) &&
      //     !flag
      //   ) {
      //     flag = true;
      //     i++;
      //   }
      //   if (i) {
      //     if (!temp[i-1]) {
      //       temp[i - 1] = [];
      //     }
      //     temp[i - 1].push(item);
      //   }
      // });
      let temp = this.group(
        data,
        Math.floor(data.length / this.mainJewelryNum)
      );
      console.log(temp, mainJewelryData);
      this.drawEveryJewelry(temp, mainJewelryData);
    },
    // eslint-disable-next-line no-unused-vars
    drawEveryJewelry(data, mainJewelryData) {
      //判断能放多少颗珠子

      // let lastCircle = {
      //   x: mainJewelryData[0].x,
      //   y: mainJewelryData[0].y,
      //   radius: this.mainJewelrySize * 2,
      // };

      // let nextCircle = {
      //   x: mainJewelryData[1].x,
      //   y: mainJewelryData[1].y,
      //   radius: this.mainJewelrySize * 2,
      // };

      // let num = 0;

      // data[0].forEach((value) => {
      //   console.log(value);
      //   let circleB = {
      //     x: value.x,
      //     y: value.y,
      //     //200改为动态 主珠尺寸
      //     radius: this.jewelrySize * 2,
      //   };
      //   if (
      //     !this.checkCircle(circleB, lastCircle) &&
      //     !this.checkCircle(circleB, nextCircle)
      //   ) {
      //     lastCircle = circleB;
      //     num++;
      //   }
      // });
      //（cm）*10 == mm  向下取整
      //子珠数量🟰 手围 * 10 - 主珠数量 * 主珠直径 / 子珠尺寸
      let num = Math.floor(
        (this.handSize * 10 - this.mainJewelrySize * this.mainJewelryNum) /
          this.jewelrySize /
          this.mainJewelryNum
      );

      data.forEach((item, index) => {
        // 平均每一颗珠子所占空间 包括间隙
        let avgNum = this.jewelrySize * 3.14 * 10;
        // Math.floor(item.length / num) == Infinity
        //   ? 0
        //   : Math.floor(item.length / num);
        // 子珠直径

        // 加一颗珠子的直径用来居中

        console.log(
          data[index].length,
          Math.floor((this.mainJewelrySize * 10 * 3.14) / 2),
          Math.floor(data[index].length - avgNum * num),
          data[index].length - avgNum * num,
          data[index].length,
          avgNum,
          num,
          "长度"
        );

        let star = Math.floor(
          (this.mainJewelrySize * 10 * 3.14) / 2 + avgNum / 2
        );
        let end = Math.floor(avgNum * num);

        console.log(star, end);
        let arr = data[index].splice(star, end);

        console.log(arr);

        // let arr = data[index];
        // arr.splice(0, this.mainJewelrySize* 10 *2);
        // arr.splice(arr.length - this.mainJewelrySize * 10);

        arr.forEach((item) => {
          var ctx = document.getElementById("canvas_1").getContext("2d");
          ctx.beginPath();
          ctx.arc(item.x, item.y, 1, 0, 2 * Math.PI);
          ctx.stroke();
        });

        avgNum = this.jewelrySize * 3.14 * 10;
        // Math.floor(arr.length / num) == Infinity
        //   ? 0
        //   : Math.floor(arr.length / num);

        // 数组总长度 arr.length 第一个圆索引为0 第n个为 radius / 2 + radius  * n
        //计算需要长度

        // let JewelrySumLen =
        //   (jewelrySize / 2) * (num - 1) + jewelrySize * (num - 1);

        // let gapDistance =
        //   (data[index].length - JewelrySumLen) / num < 0
        //     ? 0
        //     : (data[index].length - JewelrySumLen) / num;

        // let startPoint = this.mainJewelrySize * 10 * 2; // 珠子起始位置 线段长度减去这一段
        // let endPoint = data[index].length - this.mainJewelrySize * 10 * 2;
        // let JewelrySumLen =  data[index].splice((this.mainJewelrySize * 10 * 2, data[index].length - this.mainJewelrySize * 10 * 2)

        //理论周长 手围*3.14 创建的点总会认为
        // let perimeter

        //线段长度 - 珠子总需长度 / 珠子数量 (arr.length - avgNum * num) / (num - 2)
        let gapDistance =
          (arr.length - this.jewelrySize * 3.14 * 10 * num) / num < 0 ? 0 : (arr.length - this.jewelrySize * 3.14 * 10 * num) / num;

        console.log(
          "数组长度",
          arr,
          "平均每颗珠子所占距离",
          avgNum,
          "可放置子珠数",
          num,
          "间隙距离",
          gapDistance,
          this.jewelrySize * 3.14 * 10
        );

        for (let j = 0; j < num; j++) {
          var ctx = document.getElementById("canvas_1").getContext("2d");
          ctx.beginPath();
          ctx.strokeStyle = index == 0 ? "red" : "blue";
          console.log(
            "第：个元素",
            j == 0
              ? Math.floor(gapDistance + (this.jewelrySize * 10) / 2)
              : (avgNum * (j + 1) + gapDistance - 1).toFixed(0),
            "数组长度",
            arr.length
          );
          let temp =
            arr[
              j == 0
                ? // ? Math.floor(
                  //     gapDistance +
                  //       this.mainJewelrySize * 3.14 +
                  //       this.jewelrySize * 3.14
                  //   )
                  Math.abs(gapDistance.toFixed(0))
                : (
                    Math.abs(gapDistance.toFixed(0)) +
                    avgNum * j +
                    gapDistance * j -
                    1
                  ).toFixed(0)
            ];
          // * 2是因为需要1px = 4mm n*2 = 4mm下的半径
          ctx.arc(temp.x, temp.y, this.jewelrySize * 2, 0, 2 * Math.PI);
          ctx.stroke();
        }

        /**814
 * 半径 20  

0 从第一个圆心开始

第二个圆心 150 100为上一个圆半径 50 为该圆半径？
 */
        // for (let j = 0; j < num; j++) {
        //   var ctx = document.getElementById("canvas_1").getContext("2d");
        //   ctx.beginPath();
        //   ctx.strokeStyle = index == 0 ? "red" : "blue";
        //   let temp =
        //     avgNum * j == arr.length
        //       ? // 算出直径剧中
        //         arr.length - this.jewelrySize * 2
        //       : avgNum * j - this.jewelrySize * 2 < 0
        //       ? this.mainJewelrySize * 2
        //       : avgNum * j + this.jewelrySize*2;
        //   ctx.arc(arr[temp].x, arr[temp].y, this.jewelrySize, 0, 2 * Math.PI);
        //   // ctx.arc(item.x, item.y, 15, 0, 2 * Math.PI);
        //   ctx.stroke();
        // }
      });
    },
    drawMainJewelry(data) {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      for (let i = 0; i < data.length; i++) {
        ctx.beginPath();
        ctx.arc(data[i].x, data[i].y, this.mainJewelrySize * 2, 0, 2 * Math.PI);
        ctx.stroke();
      }
    },
    drawCirc() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.beginPath();
      let radius = (this.handSize * 40) / 3.14 / 2;
      ctx.arc(200, 200, radius, 0, 2 * Math.PI);
      ctx.stroke();
    },
    /**
     *  nodeNum 等分圆的点数
     *  center 圆心 {x,y}
     *  radius 半径 （直径 该处为bug
     *  return 每个元素的坐标 [{x,y},{x,y},...]
     */
    calcCircularLayout(nodeNum, center, radius) {
      radius = (radius * 40) / 3.14 / 2;
      var i,
        _i,
        _layouts = [];
      for (i = _i = 0; _i < nodeNum; i = ++_i) {
        var x = center.x + radius * Math.sin((2 * Math.PI * i) / nodeNum),
          y = center.y + radius * Math.cos((2 * Math.PI * i) / nodeNum);
        _layouts.push({ x: x, y: y });
      }
      return _layouts;
    },
    checkCircle(circleB, circleA) {
      var circleRadiusTotal = circleA.radius + circleB.radius;
      var dx = circleA.x - circleB.x;
      var dy = circleA.y - circleB.y;
      var distance = Math.sqrt(dx * dx + dy * dy);
      return distance <= circleRadiusTotal;
    },
    /** p1: 圆上的坐标点[x,y]
     *  r: 半径
     */
    getArcPath(p1, p2, r, sweepFlag = 1) {
      let L = Math.abs(p1[0] - p2[0]);
      let rotateL = -(p1[1] - p2[1]);
      if (sweepFlag === 0) L = -L;
      return `a${r},${r} 0 0,${sweepFlag} ${L},${rotateL}`; // 对比圆的特性
    },
  },
};
</script>

<style>
</style>