<template>
  <div class="badge-page">
    <div class="badge-header">
      <div class="banner-container">
        <img src="@/assets/badge/banner.png" alt="" />
      </div>
    </div>
    <!-- 徽章制作页面，录入用户信息 -->
    <div class="badge-container" v-if="!onComplete">
      <h3>バッジ作成：</h3>
      <div>
        <p>
          最大4つのバッジを作成し、任意のバッジをクリックして作成を開始します
        </p>

        <div class="preview-container" id="preview-container">
          <!-- <router-link :to="{name: 'badge-upload', params: {'seed':0}}"> -->
          <UploadAvatar :preview="0" />
          <UploadAvatar :preview="1" />
          <UploadAvatar :preview="2" />
          <UploadAvatar :preview="3" />
          <!-- </router-link> -->
          <!-- <router-link :to="{name: 'badge-upload', params: {'seed':1}}"> <UploadAvatar :preview="1" /></router-link> -->
          <!-- <router-link :to="{name: 'badge-upload', params: {'seed':2}}"> <UploadAvatar :preview="2" /></router-link> -->
          <!-- <router-link :to="{name: 'badge-upload', params: {'seed':3}}"> <UploadAvatar :preview="3" /></router-link> -->
        </div>

        <div class="badge-buttons" v-if="getPhotos">
          <button @click="createReportPhoto">制作終了</button>
        </div>

        <div style="height: 20px"></div>
      </div>
    </div>

    <div v-else class="badge-container complete-container">
      <h3>おめでとうございます、バッジ作成完了：</h3>
      <h2>受理番号：NK-2022-001</h2>
      <p>
        弊社ソフトウェアをご利用いただきありがとうございます。7日以内に制作を完了いたします。下記のQRコードを読み取り、LINEアカウントをフォローの上、受付番号を送信していただければ、受け取り方法・送信日時をLINEでご連絡いたします。
      </p>
      <p>
        お送りいただいた写真等の情報は、納品後30日間保管させていただきます。
        30日後にシステムが自動的にコンテンツを削除します。
      </p>
      <p>改めて、ご利用ありがとうございました。</p>
      <p>お受取り先住所：134-0088 東京都江戶川区西葛西5-8小島町団地4号棟108</p>
      <p>お問い合わせ番号：090-8792-0958</p>
    </div>
    <div class="badge-bottom-container">
      <img src="@/assets/badge/contactUs.png" />
    </div>
  </div>
</template>

<script>
import UploadAvatar from "./uploadAvatar.vue";
import { addBadges } from "@/api/badges.js";
// import { file } from "@/api/files.js";

export default {
  components: { UploadAvatar },
  computed: {
    getPhotos() {
      return (
        this.$store.getters.avatar0Base64 ||
        this.$store.getters.avatar1Base64 ||
        this.$store.getters.avatar2Base64 ||
        this.$store.getters.avatar3Base64
      );
    },
  },
  created() {
    let name = this.$store.getters.name;
    let phone = this.$store.getters.phone;
    if (!name || !phone) {
      this.$router.push({ name: "badge" });
    }
  },
  data() {
    return {
      onComplete: false,
    };
  },
  methods: {
    base64toFile(dataurl, filename = "file") {
      if (dataurl == null) {
        return null;
      }
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    async createReportPhoto() {
      let name = this.$store.getters.name;
      let phone = this.$store.getters.phone;
      // let img1 = this.base64toFile(this.$store.getters.avatar0Base64);
      // let img2 = this.base64toFile(this.$store.getters.avatar1Base64);
      // let img3 = this.base64toFile(this.$store.getters.avatar2Base64);
      // let img4 = this.base64toFile(this.$store.getters.avatar3Base64);

      let data = {
        name: name,
        phone: phone,
        images: [
          this.$store.getters.avatar0Base64,
          this.$store.getters.avatar1Base64,
          this.$store.getters.avatar2Base64,
          this.$store.getters.avatar3Base64,
        ],
      };

      // //文件上传接口 上传四张图片
      // if (img1) {
      //   const formData = new FormData();
      //   formData.append("file", img1);
      //   await file(formData).then((result) => {
      //     data.images[0] = result.data;
      //   });
      // }

      // if (img2) {
      //   const formData = new FormData();
      //   formData.append("file", img2);
      //   await file(formData).then((result) => {
      //     data.images[1] = result.data;
      //   });
      // }

      // if (img3) {
      //   const formData = new FormData();
      //   formData.append("file", img3);
      //   await file(formData).then((result) => {
      //     data.images[2] = result.data;
      //   });
      // }

      // if (img4) {
      //   const formData = new FormData();
      //   formData.append("file", img4);
      //   await file(formData).then((result) => {
      //     data.images[3] = result.data;
      //   });
      // }

      let self = this;
      addBadges(data).then(() => {
        self.onComplete = true;
        this.$store.dispatch("initial");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-page {
  margin: 0;
  background-color: rgba(219, 225, 251, 1);
}

.badge-header {
  width: 100%;
  background-color: #6070e1;
  .banner-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
    img {
      width: 100%;
    }
  }
}

.badge-container {
  margin: 20px 20px;
}

.preview-container {
  display: grid;
  grid-template-columns: 49.8% 49.8%;
  grid-template-rows: 50% 50%;
  grid-column-gap: 0.4%;
  background-color: white;
  // height: 612px;
}

.complete-container {
  h2 {
    text-align: center;
  }
  p {
    text-indent: 2em;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
}

.badge-buttons {
  text-align: center;
  margin: 40px auto;
  margin-bottom: 30px;
  button {
    color: white;
    background-color: #6070e1;
    border: none;
    font-size: 18px;
    padding: 10px 20px;
    margin: 0px 10px;
  }
}
.badge-bottom-container {
  width: 90%;
  margin: 40px auto;
  img {
    width: 100%;
  }
}
</style>