<template>
  <div id="bracelets-customize">
    <ul class="bracelets-box" style="width: 400px; height: 400px">
      <li
        v-for="(item, index) in bracelets"
        :key="index"
        :style="{ top: `${item.y}px`, left: `${item.x}px` }"
        class="spars-position"
        @click="changeSpar(index)"
      >
        <img
          :src="item.src"
          :style="{
            width: `${item.size}px`,
            height: `${item.size}px`,
            'pointer-events': 'none',
          }"
        />
      </li>
    </ul>
    <h3
      style="
        width: 400px;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        margin-left: auto;
        margin-right: auto;
        text-align: right;
        font-size: inherit;
        font-weight: inherit;
      "
    >
      <!-- 款式名称/尺寸大小 -->
      ブレスレット12cm
    </h3>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  data() {
    return {
      bracelets:[]
    };
  },
  created() {
    eventBus.$on('toggleSize', () => {
      this.bracelets = eventBus.bracelets[eventBus.sizeIndex].model
    })
  },  
  methods: {
    changeSpar(i) {
      let { id, name, src } = eventBus.currentSpar;
      if (!this.bracelets[i].isReplace || !src) {
        return;
      }
      let data = {
        id,
        name,
        src,
        size: this.bracelets[i].size,
        x: this.bracelets[i].x,
        y: this.bracelets[i].y,
        angle: this.bracelets[i].angle,
        isReplace: this.bracelets[i].isReplace,
      };

      this.bracelets.splice(i, 1, data);
      // eventBus.bracelets = this.bracelets
      eventBus.$emit("onBraceletsChange");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  #bracelets-customize ul {
    overflow-x: visible !important;
  }
}

#bracelets-customize {
  background-color: #efebe3;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}

#bracelets-customize {
  background-image: url(https://lithos-philia.com/img/lithos_philia/common/bg_bto_designview.jpg);
  background-repeat: repeat;
}

#bracelets-customize ul {
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.bracelets-box {
  background-color: #fff;
  margin: auto;
  overflow: hidden;
  position: relative;
  user-select: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.spars-position {
  position: absolute;
  cursor: pointer;
}
.checkbox-panel {
  position: absolute;
}
</style>